import React from "react";
import ApiProxyFactory from "./ApiProxy";
import { pricingTypes } from "./pricingTypes";
import constants from "./constants";
const { zip3 } = require("./taiwan");
const validator = require("validator");

const Context = React.createContext();
const apiProxy = new ApiProxyFactory({ apiUrl: constants.apiUrl });

function transToQueryStr(params) {
  let query = "";
  if (typeof params === "object") {
    query = Object.keys(params)
      .filter(key => params[key] || params[key] === 0) // has value
      .reduce((e, key, idx) => {
        e = e + `${idx === 0 ? "?" : "&"}${key}=${params[key]}`;
        return e;
      }, "");
  }
  return query;
}

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

class Provider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: null,
      cart: null,
      loading: false,
      delighterRefresh: false,
      spec: null,
      productNames: [],
      services: [], //for micro service
      categories: [],
      hashtags: [],
      blogLabels: [],
      faqLabels: [],
      worksLabels: [],
      openLoginModal: false,
      promoItems: [],
      agreement: {},
      specLoading: true,
      currentPath: "",
      lastPath: "",
      specsError: {},
      worksFilters: {},
    };

    this.actions = {
      setLoading: loading => this.setState({ loading }),

      setDelighter: value => this.setState({ delighterRefresh: value }),

      setToken: token => apiProxy.setToken(token),

      getJwtToken: async () => {
        return apiProxy.get({
          path: `/api/user/jwt/`,
        });
      },

      //#region GLOBAL
      setSpec: async spec => {
        try {
          this.setState({ spec });

          let productNames = [];
          for (let t of pricingTypes) {
            if (t === "custom" || !Array.isArray(spec[t])) {
              continue;
            }
            productNames = productNames.concat(
              spec[t].map(product => product.name)
            );
          }
          this.setState({ productNames });
        } catch (err) {
          console.warn("get spec fail", err);
        }
      },

      getSpec: async version => {
        if (this.state.spec && version === this.state.spec.version) {
          return this.state.spec;
        } else if (version) {
          return await (
            await fetch(`${constants.productSpecUrl}/${version}.json`)
          ).json();
        } else {
          const currentTime = Date.now();
          return await (
            await fetch(
              `${constants.productSpecUrl}/latest.json?v=${currentTime}`
            )
          ).json();
        }
      },

      //#region specsError
      setSpecsError: (field, isError) => {
        this.setState(prevState => ({
          specsError: isError
            ? { ...prevState.specsError, [field]: isError }
            : Object.keys(prevState.specsError).reduce((acc, key) => {
                if (key !== field) {
                  acc[key] = prevState.specsError[key];
                }
                return acc;
              }, {}),
        }));
      },
      //#endregion specsError

      getProductFromSpec: async ({ productName, spec = null }) => {
        const MAX_RETRIES = 10;
        const RETRY_DELAY = 1000;

        const waitForSpec = async () => {
          for (let i = 0; i < MAX_RETRIES; i++) {
            if (!this.state.specLoading) {
              return;
            }
            await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
          }
          throw new Error("Spec 加載超時");
        };

        try {
          if (this.state.specLoading) {
            await waitForSpec();
          }

          let _spec = spec || this.state.spec;

          if (!_spec) {
            throw new Error("Spec 未找到");
          }

          for (let t of pricingTypes) {
            if (Array.isArray(_spec[t])) {
              const product = _spec[t].find(p => p.name === productName);
              if (product) {
                return product;
              }
            }
          }

          return null;
        } catch (error) {
          console.error("getProductFromSpec error:", error.message);
          throw error;
        }
      },

      getProducts: async ({ category, sort }) => {
        const resp = await apiProxy.get({
          path: `/api/product/?is_on_shelf=true`,
        });

        let products = resp.results;

        // TODO: maybe use backend to filter?
        if (category && category.name !== "" && category.name !== "root") {
          products = products.filter(
            p => p.label && p.label.indexOf(category.name) > -1
          );
        }

        // TODO: apply sort
        const fnCmp = ({ key, desc = false }) => (p1, p2) => {
          const k1 = p1[key];
          const k2 = p2[key];

          const factor = desc ? -1 : 1;
          if (k1 === k2) {
            return 0;
          } else if (k1 > k2) {
            return 1 * factor;
          } else {
            return -1 * factor;
          }
        };

        if (sort === "+price") {
          products = products.sort(fnCmp({ key: "price" }));
        } else if (sort === "-price") {
          products = products.sort(fnCmp({ key: "price", desc: true }));
        } else if (sort === "+updated") {
          products = products.sort(fnCmp({ key: "created" }));
        } else if (sort === "-updated") {
          products = products.sort(fnCmp({ key: "created", desc: true }));
        }

        return products;
      },

      getProduct: async ({ name, id } = {}) => {
        const path = name
          ? `/api/product/?name=${encodeURIComponent(name)}&is_on_shelf=true`
          : `/api/product/${id}?is_on_shelf=true`;
        return apiProxy.get({
          path,
        });
      },

      calcProduct: async itemConfig => {
        return apiProxy.post({
          withHost: true,
          path: constants.calcPriceUrl,
          data: itemConfig,
        });
      },

      getServices: async () => {
        try {
          let resp = await apiProxy.get({
            path: `${constants.revServiceApi}?client_id=${constants.revClientId}`,
            withHost: true,
          });
          this.setState({ services: resp });
        } catch (err) {}
      },

      getCategories: async () => {
        try {
          let resp = await apiProxy.post({
            path: `${constants.revJsonStorageHost}/document/categories/find-one?client_id=${constants.revClientId}`,
            withHost: true,
            data: { query: { name: "products" } },
          });

          this.setState({ categories: resp.children });
        } catch (err) {
          console.warn("Fail: Get Categories");
        }
      },

      getHashtags: async () => {
        try {
          let resp = await apiProxy.post({
            path: `${constants.revJsonStorageHost}/document/categories/find-one?client_id=${constants.revClientId}`,
            withHost: true,
            data: { query: { name: "hashtags" } },
          });

          this.setState({ hashtags: resp.children });
        } catch (err) {
          console.warn("Fail: Get Hashtags");
        }
      },
      //#endregion GLOBAL

      //#region AUTH
      setLoginModal: open => {
        this.setState({ openLoginModal: open });
      },

      login: async ({ username, password, gui_number }) => {
        try {
          let resp = null;

          if (gui_number) {
            resp = await apiProxy.post({
              path: `/api/user/ent/login/`,
              data: {
                gui_number,
                identity: username,
                password,
              },
            });
          } else {
            resp = await apiProxy.post({
              path: `/api/user/login/`,
              data: {
                username: !validator.isEmail(username) ? username : undefined,
                email: validator.isEmail(username) ? username : undefined,
                password,
              },
            });
          }

          this.setState({ profile: resp });
          apiProxy.setToken(resp.token);
          window.localStorage.setItem("token", resp.token);
        } catch (ex) {
          apiProxy.setToken(null);
          window.localStorage.removeItem("token");
          throw ex;
        }
      },

      autoLogin: async () => {
        let token = window.localStorage.getItem("token");

        if (token) {
          apiProxy.setToken(token);
          try {
            let resp = await apiProxy.get({
              path: `/api/user/profile/`,
            });
            this.setState({ profile: resp });
            return resp;
          } catch (ex) {
            apiProxy.setToken(null);
            window.localStorage.removeItem("token");
            console.error("auto login failed");
          }
        }
      },

      logout: async () => {
        this.setState({ profile: null, cart: null });
        apiProxy.setToken(null);
        window.localStorage.removeItem("token");
      },

      //此api 也會 確認信箱有無已註冊
      validEmail: async ({ email }) => {
        return apiProxy.post({
          path: `/api/user/profile/validation/request/`,
          data: {
            identity: email,
          },
        });
      },

      register: async ({ access_token, password }) => {
        return apiProxy.post({
          path: `/api/user/register/`,
          data: {
            access_token,
            password,
          },
        });
      },

      setEnterpriseRegister: bool => {
        window.localStorage.setItem("ent_register", bool);
      },

      isEnterpriseRegister: async () => {
        let value = await window.localStorage.getItem("ent_register");
        return value === "true" ? true : false;
      },

      enterpriseRegister: async ({ access_token, password, ...data }) => {
        return apiProxy.post({
          path: `/api/user/ent/register/`,
          data: {
            access_token,
            password,
            ...data,
          },
        });
      },

      editMyProfile: async data => {
        let resp;
        if (data.avatar instanceof File) {
          let formData = new FormData();
          delete data.user;
          for (let key in data) {
            formData.append(key, data[key]);
          }

          resp = await apiProxy.formPut({
            path: "/api/user/profile/",
            formData,
          });
        } else {
          delete data.avatar;
          delete data.user;
          resp = await apiProxy.put({
            path: "/api/user/profile/",
            data,
          });
        }
        this.setState({ profile: resp });
        return resp;
      },

      requestToUpgradeUserType: async data => {
        return apiProxy.post({
          path: `/auth/user/upgrade`,
          data,
        });
      },

      getMyLatestUpgradeUserTypeRecord: async () => {
        let resp = await apiProxy.get({
          path: `/auth/user/upgrade/list`,
        });
        let upgradeList = resp.results;
        let profile = this.state.profile;

        // detect if there is any pending record first, otherwise, recognized user type
        let pendingRecord = upgradeList.find(
          record => record.state == "pending"
        );
        if (pendingRecord !== undefined) {
          return pendingRecord;
        }

        let lastetRecord = {};
        if (profile.user_type === "normal") {
          lastetRecord = upgradeList.filter(
            record => record.req_to === "enterprise"
          )[0];
        } else if (profile.user_type === "vip") {
          lastetRecord = upgradeList.filter(
            record => record.req_to === "ent_vip"
          )[0];
        } else if (profile.user_type === "enterprise") {
          lastetRecord = upgradeList.filter(
            record => record.req_to === "monthly"
          )[0];
        } else if (profile.user_type === "ent_vip") {
          lastetRecord = upgradeList.filter(
            record => record.req_to === "ent_vip_monthly"
          )[0];
        }

        return lastetRecord;
      },

      getMyProfile: async () => {
        let resp = await apiProxy.get({
          path: `/api/user/profile/`,
        });
        this.setState({ profile: resp });
        return resp;
      },

      forgetPassword: async ({ email }) => {
        return apiProxy.post({
          path: `/api/user/password/forgot/`,
          data: {
            email,
          },
        });
      },

      changePassword: async ({ username, password, newPassword }) => {
        return apiProxy.post({
          path: `/api/user/change_password/`,
          data: {
            username,
            password,
            new_password: newPassword,
          },
        });
      },

      searchProfile: async ({ keyword }) => {
        return apiProxy.get({
          path: `/api/user/profile/all/?search=${keyword}`,
        });
      },

      //#endregion AUTH

      //#region  BLOG
      getBlogLabels: async () => {
        try {
          let resp = await apiProxy.post({
            path: `${constants.revJsonStorageHost}/document/categories/find-one?client_id=${constants.revClientId}`,
            withHost: true,
            data: { query: { name: "articles" } },
          });

          this.setState({ blogLabels: resp.children });
        } catch (err) {
          console.warn("Fail: Get Blog Labels");
        }
      },

      getFaqLabels: async () => {
        try {
          let resp = await apiProxy.post({
            path: `${constants.revJsonStorageHost}/document/categories/find-one?client_id=${constants.revClientId}`,
            withHost: true,
            data: { query: { name: "faqs" } },
          });

          this.setState({ faqLabels: resp.children });
        } catch (err) {
          console.warn("Fail: Get Faq Labels");
        }
      },

      getBlogs: async data => {
        return apiProxy.post({
          path: `${constants.revJsonStorageHost}/document/Article_Default/find?client_id=${constants.revClientId}`,
          withHost: true,
          data,
        });
      },

      getBlog: async data => {
        return apiProxy.post({
          path: `${constants.revJsonStorageHost}/document/Article_Default/find-one?client_id=${constants.revClientId}`,
          withHost: true,
          data,
        });
      },

      //#endregion BLOG

      //#region ORDER
      getPeriodCheckoutUrl: async data => {
        if (!apiProxy.apiToken) {
          throw new Error("找不到 token");
        }
        return apiProxy.get({
          path: `/checkout/neweb/period/request`,
          extraHeaders: { "Cache-Control": "no-cache" },
        });
        // return `${apiProxy.apiUrl}/checkout/neweb/period/request?token=${apiProxy.apiToken}`
      },

      getPeriod: async id => {
        return apiProxy.get({
          path: `/api/period/order/${id}`,
          secure: true,
        });
      },

      editPeriodNote: async ({ id, note }) => {
        return apiProxy.post({
          path: `/api/period/order/update/${id}/`,
          data: {
            note,
            // status will be modified to review_waiting by backend
          },
        });
      },

      editOrderRemit: async (id, data) => {
        return apiProxy.post({
          path: `/checkout/order/remit/${id}/`,
          data,
        });
      },

      calcOrder: async data => {
        // estimate cart calculation
        return apiProxy.post({
          path: `/api/order/calc/`,
          data,
        });
      },

      getCsvUrl: () =>
        `${constants.calcHost}/cvs?client_id=${constants.revClientId}`,
      //#endregion ORDER

      //#region PROMOTION
      getPromotions: async () => {
        return apiProxy.get({
          path: `/api/promotion/diff/`,
        });
      },

      getCoupon: async code => {
        return apiProxy.get({
          path: `/api/coupon/${code}`,
        });
      },
      //#endregion PROMOTION

      getCounties: () => {
        return [...new Set(zip3.map(x => x.city))].map(x => ({
          countyName: x,
        }));
        return apiProxy.get({
          path: `https://daiwanlang.netlify.app/api/行政區/get`,
          withHost: true,
          secure: false,
        });
      },

      getDistricts: county => {
        return zip3
          .filter(x => x.city === county)
          .map(x => ({ townName: x.district, zipCode: x.zip }));
        return apiProxy.get({
          path: `https://daiwanlang.netlify.app/api/行政區/${county}/get`,
          withHost: true,
          secure: false,
        });
      },

      getZipCode: zipcode => {
        let result = zip3.find(x => x.zip === zipcode);
        if (result) {
          return {
            countyName: result.city,
            townName: result.district,
          };
        }
        // apiProxy.get({
        //   path: `https://daiwanlang.netlify.app/api/行政區/郵遞區號/${zipcode}/get`,
        //   withHost: true,
        //   secure: false,
        // })
      },

      getUploadPresignUrl: async (file, options = {}, token) => {
        const fileKey = file.name; //.split(".")[0];
        const fileType = file.type;
        const { acl = "public-read" } = options;

        return apiProxy.post({
          path: `${constants.revStorageHost}/storage/presigned/url?client_id=${
            constants.revClientId
          }${token ? "&token=" + token : ""}`,
          withHost: true,
          secure: false,
          data: {
            acl,
            "Content-Type": fileType,
            key: `${fileKey}`,
          },
        });
      },

      contact: async data => {
        if (!data.data) {
          delete data.data;
        }

        return apiProxy.post({
          path: `/api/contact/`,
          data,
        });
      },

      getHistories: async (data, jwtToken) => {
        return apiProxy.post({
          path: `${constants.revJsonStorageHost}/document/history/find?token=${jwtToken}`,
          withHost: true,
          data,
        });
      },

      //#region works
      getWorksLabels: async () => {
        try {
          let resp = await apiProxy.post({
            path: `${constants.revJsonStorageHost}/document/categories/find-one?client_id=${constants.revClientId}`,
            withHost: true,
            data: { query: { name: "works" } },
          });

          this.setState({ worksLabels: resp.children });
        } catch (err) {
          console.warn("Fail: Get Blog Labels");
        }
      },

      setWoksFilters: filters => {
        this.setState(prev => ({
          ...prev,
          worksFilters: { ...prev.worksFilters, ...filters },
        }));
      },

      getWorks: data => {
        return apiProxy.post({
          path: `${constants.revJsonStorageHost}/document/works/find?client_id=${constants.revClientId}`,
          withHost: true,
          data,
        });
      },

      getWork: id => {
        return apiProxy.post({
          path: `${constants.revJsonStorageHost}/document/works/find-one?client_id=${constants.revClientId}`,
          withHost: true,
          data: { query: { id } },
        });
      },

      getReurl: ({ id, image, title, outline, path = "/article" }) =>
        `${
          constants.apiUrl
        }/api/reurl?image=${image}&title=${title}&redirect_url=${
          constants.webUrl
        }${path}?id=${id}${outline ? `&description=${outline}` : ``}`,

      //#endregion works

      //#region promotion
      getPromoItems: async () => {
        // type = banner || top_zone || bottom_zone
        let promoItems = await apiProxy.get({
          path: `/api/promo_item/`,
        });

        this.setState({ promoItems });
      },
      //#endregion promotion
      getIframeToken: async id => {
        let { token } = await apiProxy.post({
          path: "/api/v1/payment/iframe/token",
          data: {
            order: id,
          },
        });
        return token;
      },
      getIframe3DUrl: async encrypt_info => {
        let { url } = await apiProxy.post({
          path: "/api/v1/payment/iframe/3d",
          data: {
            encrypt_info,
          },
        });
        return url;
      },
      getAgreement: async () => {
        try {
          let resp = await apiProxy.post({
            path: `${constants.revJsonStorageHost}/document/misc/find-one?client_id=${constants.revClientId}`,
            withHost: true,
            data: { query: { key: "agreement" } },
          });

          console.log(resp);

          this.setState({
            agreement: { content: resp.content, html: resp.html },
          });
        } catch (err) {
          console.warn("Fail: Get Agreement");
        }
      },
      getPayuniShipMap: (mobile = false, redirect_url) =>
        `${
          constants.apiUrl
        }/api/v1/logistics/payuni/ship-map?redirect_url=${redirect_url}${
          mobile ? "&mobile=true" : ""
        }`,
      getXdeliveryShipMap: redirect_url =>
        `${constants.apiUrl}/api/v1/logistics/xdelivery/ship-map?redirect_url=${redirect_url}`,

      setCurrentPath: path => {
        //如果 path 有變化才會觸發
        if (this.state.currentPath !== path) {
          this.setState({ lastPath: this.state.currentPath });
          this.setState({ currentPath: path });
        }
      },

      goBack: () => {
        if (this.state.lastPath) {
          window.history.back();
        } else {
          window.location.href = "/";
        }
      },
    };
  }

  async componentDidMount() {
    this._initSpec();
    await this.actions.getServices();
    this.actions.getCategories();
    this.actions.getHashtags();
    this.actions.getBlogLabels();
    this.actions.getFaqLabels();
    this.actions.getWorksLabels();
    this.actions.getPromoItems();
    this.actions.getAgreement();
  }

  render() {
    return (
      <Context.Provider
        value={{
          state: this.state,
          actions: this.actions,
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }

  _initSpec = async () => {
    this.setState({ specLoading: true });
    let spec = await this.actions.getSpec();
    this.actions.setSpec(spec);
    this.setState({ specLoading: false });
  };
}

export { Context, Provider };
