import { combineReducers } from "redux";
import user from "./User";
import lang from "./Lang";
import rwdSidebarOpen from "./RwdSidebarOpen";

export default combineReducers({
  user,
  lang,
  rwdSidebarOpen,
});
