import { navigate } from "gatsby";
import Actions from "./Reducers/Actions";
import Api from "./Api";
import * as Cart from "./Contexts/CartContext";
import { toggleRwdSidebar } from "./Reducers/RwdSidebarOpen";
const validator = require("validator");

export default dispatch => ({
  navActions: {
    push: async (url, param) => {
      if (!param) {
        navigate(`/${url}`);
      } else {
        navigate(`/${url}`, param);
      }
    },
  },

  appActions: {
    setLang: lang => {
      dispatch({ type: Actions.SET_LANG, payload: lang });
    },

    echo: async () => {
      return await Api.echo("hello");
    },

    getToken: () => Api.getToken(),

    autoLogin: async ({ setToken }) => {
      let token = window.localStorage.getItem("token");
      let result = false;
      dispatch({ type: Actions.AUTO_LOGIN_DONE, payload: false });

      if (token) {
        Api.setToken(token);
        Cart.Actions.setToken(token);
        setToken(token);
        let response = null;
        try {
          response = await Api.getMyProfile();
        } catch (ex) {
          if (!(ex instanceof TypeError)) {
            Api.setToken(null);
            Cart.Actions.setToken(null);
            setToken(null);
            window.localStorage.removeItem("token");
          }
          result = false;
        }
        dispatch({ type: Actions.LOGIN, payload: response });
        result = true;
      }
      dispatch({ type: Actions.AUTO_LOGIN_DONE, payload: true });
      return result;
    },

    login: async ({ username, password, gui_number, setToken }) => {
      try {
        let response = null;

        if (gui_number) {
          response = await Api.loginEnterprise({
            username,
            password,
            gui_number,
          });
        } else {
          response = await Api.login({
            ...(validator.isEmail(username)
              ? { email: username }
              : { username }),
            password,
          });
        }

        Api.setToken(response.token);
        Cart.Actions.setToken(response.token);
        setToken(response.token);
        window.localStorage.setItem("token", response.token);
        dispatch({ type: Actions.LOGIN, payload: response });
      } catch (ex) {
        Api.setToken(null);
        setToken(null);
        window.localStorage.removeItem("token");
        throw ex;
      }
    },

    logout: async ({ setToken }) => {
      dispatch({ type: Actions.LOGOUT, payload: null });
      dispatch({ type: Actions.AUTO_LOGIN_DONE, payload: true });
      Api.setToken(null);
      Cart.Actions.setToken(null);
      setToken(null);
      window.localStorage.removeItem("id");
      window.localStorage.removeItem("token");
    },

    validEmail: params => {
      return Api.validEmail(params);
    },

    register: async params => {
      return await Api.register(params);
    },

    setEnterpriseRegister: bool => {
      window.localStorage.setItem("ent_register", bool);
    },

    isEnterpriseRegister: async () => {
      let value = await window.localStorage.getItem("ent_register");
      return value === "true" ? true : false;
    },

    enterpriseRegister: async data => {
      return Api.enterpriseRegister(data);
    },

    editMyProfile: async data => {
      let payload = await Api.editMyProfile(data);
      dispatch({
        type: Actions.AUTO_LOGIN,
        payload,
      });
      return payload;
    },

    requestToUpgradeUserType: async data => {
      let payload = await Api.requestToUpgradeUserType(data);
      return payload;
    },

    getMyLatestUpgradeUserTypeRecord: async () => {
      let resp = await Api.getMyUpgradeUserTypeRecordList();
      let upgradeList = resp.results;
      let myProfile = await Api.getMyProfile();

      let lastetRecord = {};
      // detect if there is any pending record first, otherwise, recognized user type
      let pendingRecord = upgradeList.find(record => record.state == "pending");
      if (pendingRecord !== undefined) {
        return pendingRecord;
      }

      if (myProfile.user_type === "normal") {
        lastetRecord = upgradeList.filter(
          record => record.req_to === "enterprise"
        )[0];
      } else if (myProfile.user_type === "vip") {
        lastetRecord = upgradeList.filter(
          record => record.req_to === "ent_vip"
        )[0];
      } else if (myProfile.user_type === "enterprise") {
        lastetRecord = upgradeList.filter(
          record => record.req_to === "monthly"
        )[0];
      } else if (myProfile.user_type === "ent_vip") {
        lastetRecord = upgradeList.filter(
          record => record.req_to === "ent_vip_monthly"
        )[0];
      }

      return lastetRecord;
    },

    getMyProfile: async () => {
      try {
        await Api.getMyProfile();
      } catch (ex) {
        console.warn(ex);
      }
    },

    forgetPassword: async ({ email }) => {
      return Api.forgetPassword({ email });
    },

    changePassword: async ({ username, password, newPassword }) => {
      try {
        await Api.changePassword({ username, password, newPassword });
        return Promise.resolve();
      } catch (err) {
        console.warn(err);
        return Promise.reject(err);
      }
    },

    searchProfile: async ({ keyword }) => {
      return Api.searchProfile({ keyword });
    },

    getJwtToken: async () => Api.getJwtToken(),

    createCustomOrder: async params => {
      return Api.createCustomOrder(params);
    },

    adminUpdateOrderBuyer: async ({ id, buyer }) => {
      return Api.adminUpdateOrder({ id, buyer });
    },

    contactFromSendEmail: async ({
      subject,
      message,
      name,
      phone,
      local_phone,
      email,
      data = "",
    }) => {
      return Api.contactFormSendEmail({
        subject,
        message,
        name,
        phone,
        local_phone,
        email,
        data,
      });
    },

    voidOrder: params => {
      return Api.voidOrder(params);
    },

    getLogistics: params => Api.getLogistics(params),

    getCustomCalculations: params => Api.getCustomCalculations(params),

    toggleRwdSidebar: () => dispatch({ type: Actions.TOGGLE_RWD_SIDEBAR }),

    closeRwdSidebar: () => dispatch({ type: Actions.CLOSE_RWD_SIDEBAR }),

    openRwdSidebar: () => dispatch({ type: Actions.OPEN_RWD_SIDEBAR }),
  },
});
