import React, { Fragment, useContext, useState, useEffect } from "react";
import styled from "styled-components";
import * as Theme from "../Theme";
import { isEnt } from "../Domain/RoleValidator";
import { connect } from "react-redux";
import ActionCreator from "../ActionCreator";
import Selectors from "../Selectors";
import * as Ant from "antd";
import * as Icon from "./Icon";
import { navigate } from "gatsby";
import { Context } from "../AppContext";

const entries = [
  {
    name: "profile",
    label: "會員資料",
    subtitle: "Profile",
    entries: [
      { name: "myProfile", label: "基本資料" },
      { name: "changePw", label: "修改登入密碼" },
      // { name: "reqEnt", label: "申請成為企業會員" },
      // { name: "reqMonthly", label: "申請成為企業月結會員" },
      // { name: "address", label: "訂單常用地址" },
    ],
  },
  {
    name: "orders",
    label: "我的訂單",
    subtitle: "Orders",
    entries: [
      { name: "order", label: "全部訂單" },
      { name: "orderWaiting", label: "等待付款訂單" },
      { name: "orderItemRejected", label: "稿件不合格訂單" },
      // { name: "orderFinished", label: "付款已完成訂單" },
    ],
  },
  {
    name: "periods",
    label: "我的訂閱",
    subtitle: "Subscribe",
    entries: [{ name: "period_orders", label: "全部訂單" }],
    // hidden: !isEnt(profile),
  },
  {
    name: "bonus_history",
    label: "我的印幣",
    subtitle: "Bonus",
    // type: "preparing",
    entries: [{ name: "bonusHistory", label: "印幣交易紀錄" }],
  },
  {
    name: "account_manage",
    label: "我的帳戶",
    subtitle: "Account",
    entries: [
      { name: "depositCredit", label: "點數加值" },
      { name: "depositRecord", label: "加值訂單" },
      { name: "creditsHistory", label: "點數交易記錄" },
    ],
  },
  {
    name: "monthly_manage",
    label: "月結服務",
    subtitle: "Monthly",
    entries: [{ name: "monthlyRecord", label: "月結交易紀錄" }],
    // hidden: !isEnt(profile),
  },

  {
    name: "logout",
    subtitle: "Log out",
    label: "登出",
    type: "function",
  },
];

const USER_TYPE = {
  normal: "一般會員",
  vip: "預儲會員",
  enterprise: "企業會員",
  ent_vip: "企業預繳會員",
  monthly: "企業月結會員",
  ent_vip_monthly: "企業月結預儲會員",
};

function ProfileNavbar(props) {
  const { profile, appActions } = props;
  const app = useContext(Context);
  const [mobileView, setMobileView] = useState(false);
  const currentPath = app.state.currentPath;

  useEffect(() => {
    if (typeof window !== "undefined") {
      setMobileView(window.innerWidth <= Theme.breakpoints.xs);
      app.actions.setCurrentPath(window.location.pathname.slice(1));

      const handleResize = () => {
        setMobileView(window.innerWidth <= Theme.breakpoints.xs);
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  return (
    <ProfileNavbarWrapper>
      {mobileView ? (
        <div className="mobile-profile-info">
          <MobileProfileInfo />
        </div>
      ) : (
        <>
          <div className="title-block">
            <div className="title">{`Ｍember   /   會員中心`}</div>
          </div>
          <div className="profile-info">
            <ProfileInfo />
          </div>
        </>
      )}
      {entries
        .filter(entry => {
          if (entry.name === "monthly_manage") {
            return ["monthly", "ent_vip_monthly"].includes(profile?.user_type);
          }
          return true;
        })
        .map(entry => {
          if (entry.name === "monthly" && !isEnt(profile)) {
            return null;
          }

          return (
            <div
              className={`item ${currentPath === entry.name ? "active" : ""}`}
              key={entry.name}
              onClick={() => {
                if (entry.name === "logout") {
                  app.actions.logout();
                  // TODO: deprecated
                  appActions.logout({
                    setToken: app.actions.setToken,
                  });

                  return;
                }
                if (entry.type === "preparing") {
                  return Ant.message.info(`${entry.label}服務 尚未開放`);
                }
                navigate(`/${entry.name}`);
                appActions.closeRwdSidebar();
              }}
              style={{ cursor: "pointer" }}
            >
              <div className="title">{entry.label}</div>
              {currentPath === entry.name && (
                <div className="subtitle">{entry.subtitle}</div>
              )}
            </div>
          );
        })}
    </ProfileNavbarWrapper>
  );
}

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
  }),
  ActionCreator
)(ProfileNavbar);

const ProfileNavbarWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  & > .title-block {
    background-color: ${Theme.colors.main};
    padding: 18px 26px;
    & > .title {
      color: ${Theme.colors.textReverse};
      text-align: center;
      white-space: break-spaces;
    }
  }

  & > .profile-info {
  }

  & > .item {
    color: ${Theme.colors.unSelected};
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-top: solid 1px ${Theme.colors.border_grey};
    cursor: default;

    & > .subtitle {
      font-size: 12px;
      color: #9b9b9b;
    }

    &.active {
      color: ${Theme.colors.main};
    }

    &:hover {
      color: ${Theme.colors.brown};
    }

    @media only screen and (max-width: ${Theme.breakpoints.xs}px) {
      display: flex;
      align-items: start;
      width: 100%;
      padding: 16px 32px;

      & > .title {
        font-weight: 700;
      }

      & > .subtitle {
        display: none;
      }
    }
  }

  @media only screen and (max-width: ${Theme.breakpoints.xs}px) {
    display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;

  & > .mobile-profile-info {
    width: 100%;
  }
`;

function ProfileInfo({ appActions }) {
  const app = React.useContext(Context);
  const { profile } = app.state;

  async function editProfile({ file }) {
    if (file) {
      app.actions.setLoading(true);
      try {
        await app.actions.editMyProfile({
          avatar: file,
        });
        Ant.message.success("更換頭像成功");
      } catch (err) {
        console.warn(err);
        Ant.message.error("更換頭像錯誤");
      }
      app.actions.setLoading(false);
    }
  }
  if (!profile) {
    return null;
  }

  return (
    <Col className="container">
      <Avatar
        avatar={
          profile.avatar === ""
            ? "../../images/default-avatar.jpg"
            : `${profile.avatar}` || `${profile.social_avatar}`
        }
        css={{ marginBottom: 40, marginTop: 60 }}
      >
        <Ant.Upload
          beforeUpload={() => false}
          fileList={[]}
          onChange={editProfile}
        >
          <AvatarEdit src="../../images/edit-avatar.png" />
        </Ant.Upload>
      </Avatar>
      <div className="data-container">
        <div className="name">{profile.name}</div>
        <div className="data">
          #{profile.id}{" "}
          {profile.social_type === "google"
            ? "Google 登入"
            : profile.social_type === "facebook"
            ? "FB 登入"
            : "一般信箱登入"}{" "}
          {USER_TYPE[profile.user_type] || ""}
        </div>

        <div className="data">{profile.group_name}</div>

        <div className="data">
          剩餘加值點數｜
          <span style={{ color: Theme.colors.brown }}>
            {profile.credits} 點
          </span>{" "}
        </div>
        <div className="data">
          累計回饋印幣｜
          <span style={{ color: Theme.colors.brown }}>{profile.bonus} 元</span>
        </div>
      </div>
    </Col>
  );
}

function MobileProfileInfo({ appActions }) {
  const app = React.useContext(Context);
  const { profile } = app.state;
  const [showProfile, setShowProfile] = useState(false);

  async function editProfile({ file }) {
    if (file) {
      app.actions.setLoading(true);
      try {
        await app.actions.editMyProfile({
          avatar: file,
        });
        Ant.message.success("更換頭像成功");
      } catch (err) {
        console.warn(err);
        Ant.message.error("更換頭像錯誤");
      }
      app.actions.setLoading(false);
    }
  }
  if (!profile) {
    return null;
  }

  return (
    <Col className="container">
      <div className="avatar-container">
        <Avatar
          avatar={
            profile.avatar === ""
              ? "../../images/default-avatar.jpg"
              : `${profile.avatar}` || `${profile.social_avatar}`
          }
          css={{ width: 52, height: 52 }}
        >
          <Ant.Upload
            beforeUpload={() => false}
            fileList={[]}
            onChange={editProfile}
          >
            <AvatarEdit src="../../images/edit-avatar.png" />
          </Ant.Upload>
        </Avatar>
        <div
          className="collapse-container"
          onClick={e => {
            e.stopPropagation();
            setShowProfile(!showProfile);
          }}
        >
          <div className="name">{profile.name}</div>
          {showProfile ? (
            <Icon.KeyboardArrowUp size={20} />
          ) : (
            <Icon.KeyboardArrowDown size={20} />
          )}
        </div>
      </div>
      {showProfile && (
        <div className="data-container">
          <div className="data">
            {USER_TYPE[profile.user_type] || ""} / {profile.group_name}
          </div>

          <div className="data">
            剩餘點數｜
            <span style={{ color: Theme.colors.brown }}>
              {profile.credits} 點
            </span>{" "}
          </div>
          <div className="data">
            回饋印幣｜
            <span style={{ color: Theme.colors.brown }}>
              {profile.bonus} 元
            </span>
          </div>
        </div>
      )}
    </Col>
  );
}

ProfileInfo = connect((state, ownProps) => ({}), ActionCreator)(ProfileInfo);

function BreadCrumb({ entry, leafNode }) {
  const app = React.useContext(Context);

  return (
    <>
      {window.innerWidth > Theme.breakpoints.xs && (
        <Row
          style={{ color: "#A0A0A0", marginBottom: 20 }}
          className="container"
        >
          {" "}
          會員中心 <span style={{ margin: "0 10px" }}>/</span> {entry?.label}{" "}
          <span style={{ margin: "0 10px" }}>/</span> {leafNode?.label}
        </Row>
      )}
      {window.innerWidth <= Theme.breakpoints.xs && (
        <Row className="go-back-container">
          <div
            className="go-back"
            onClick={() => {
              app.actions.goBack();
            }}
          >
            <img
              src="../../images/arrow_back_ios.svg"
              width="20px"
              height="auto"
            />
            <div>返回</div>
          </div>
        </Row>
      )}
    </>
  );
}

function TabContainer({ entry, leafNode, children, onTabClick = () => {} }) {
  if (entry && Array.isArray(entry.entries)) {
    return (
      <Fragment>
        <Row className="container">
          {entry.entries.map(e => (
            <TabItem
              key={e.name}
              selected={leafNode.name === e.name}
              onClick={() => onTabClick(e)}
            >
              {e.label}
            </TabItem>
          ))}
          <TabItem selected={false} css={{ flex: 1, color: "white" }}>
            {" ----- "}
          </TabItem>
        </Row>
        <TabContent>{children}</TabContent>
      </Fragment>
    );
  }
  return null;
}

export { entries, ProfileInfo, BreadCrumb, TabContainer };

const Avatar = styled.div`
  background-color: #dcdddd;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-image: url(${props => props.avatar});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;

  ${props => props.css}
`;

const AvatarEdit = styled.img`
  position: absolute;
  width: 35px;
  height: 35px;
  right: 0;
  bottom: 0;
  cursor: pointer;

  @media only screen and (max-width: ${Theme.breakpoints.xs}px) {
    width: 20px;
    height: 20px;
  }
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > .data-container {
    flex: 1;
    color: ${Theme.colors.main};
    font-size: 14px;
    margin-bottom: 40px;
    & > .name {
      font-size: 16px;
      margin-bottom: 12px;
      font-weight: 600;
      text-align: center;
    }
    & > .data {
      text-align: center;
      line-height: 28px;
    }
  }

  @media only screen and (max-width: ${Theme.breakpoints.sm}px) {
    & .container {
      margin: 0 20px;
    }
  }

  @media only screen and (max-width: ${Theme.breakpoints.xs}px) {
    padding: 24px 32px;
    gap: 16px;
    & > .avatar-container {
      display: flex;
      gap: 16px;
      align-items: center;
      width: 100%;
      font-size: 24px;
      color: "#000";
      font-weight: 700;
      line-height: 36px;

      & > .collapse-container {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    & > .data-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
      margin: 0;

      & > .data {
        font-size: 14px;
        line-height: 20px;
        text-align: left;
      }
    }
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => props.justify || "flex-start"};

  @media only screen and (max-width: ${Theme.breakpoints.sm}px) {
    & .container {
      margin: 0 20px;
    }
  }

  @media only screen and (max-width: ${Theme.breakpoints.xs}px) {
    &.go-back-container {
      margin-bottom: 16px;

      & > .go-back {
        font-size: 16px;
        font-weight: 500;
        display: flex;
        cursor: pointer;
      }
    }
  }
`;

const TabItem = styled.div`
  @media only screen and (min-width: ${Theme.breakpoints.xs}px) {
    padding: 15px 30px;
    margin-bottom: 35px;
    border-bottom: ${props =>
      props.selected ? "none" : `1px solid ${Theme.colors.border_grey}`};
    border-top: ${props =>
      props.selected ? `1px solid ${Theme.colors.border_grey}` : "none"};
    border-left: ${props =>
      props.selected ? `1px solid ${Theme.colors.border_grey}` : "none"};
    border-right: ${props =>
      props.selected ? `1px solid ${Theme.colors.border_grey}` : "none"};
    border-radius: 5px 5px 0px 0px;
    text-align: center;
    color: ${props =>
      props.selected ? Theme.colors.main : Theme.colors.unSelected};
  }
  @media only screen and (max-width: ${Theme.breakpoints.xs}px) {
    padding: 8px 16px;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 500;
    color: ${props =>
      props.selected ? Theme.colors.selected : Theme.colors.unSelected};
    border-bottom: ${props =>
      props.selected ? `1px solid ${Theme.colors.selected}` : "none"};
  }
  cursor: default;
  transition: all 0.2s linear;
  ${props => props.css}
`;

const TabContent = styled.div`
  min-height: 50vh; // hot fix: for hack profile navber bkgcolor
`;
