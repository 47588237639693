import * as _User from "./Reducers/User";
import * as _Lang from "./Reducers/Lang";
import * as _RwdSidebarOpen from "./Reducers/RwdSidebarOpen";
import { namedExportWithMemorizedOnce } from "./Utils/FpUtil";
import ParseQuery from "./Utils/ParseQuery";

let User = namedExportWithMemorizedOnce(_User);
let Lang = namedExportWithMemorizedOnce(_Lang);
let RwdSidebarOpen = namedExportWithMemorizedOnce(_RwdSidebarOpen);

const selectors = {
  getQueryParams: ownProps => ParseQuery(ownProps.location.search),
  getLang: state => Lang.getLang(state.lang),
  getLoginUser: state => User.getLoginUser(state.user),
  autoLoginDone: state => User.autoLoginDone(state.user),
  getRwdSidebarOpen: state =>
    RwdSidebarOpen.getRwdSidebarOpen(state.rwdSidebarOpen),
};

export default selectors;
