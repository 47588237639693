import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// reference: https://github.com/gatsbyjs/gatsby/issues/2293

const StaticImage = ({ filename, style = {} }) => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 1200
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    `}
    render={data => {
      const matchedImage = data.allImageSharp.edges.find(element => {
        return (
          element.node.gatsbyImageData.images.fallback.src.split("/").pop() ===
          filename
        );
      });

      return (
        <GatsbyImage
          image={getImage(matchedImage.node)}
          style={style}
          {...(style.objectFit ? { objectFit: style.objectFit } : {})}
        />
      );
    }}
  />
);

export default StaticImage;
