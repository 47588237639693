import Actions from "./Actions";

const initialState = {
  isOpen: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Actions.TOGGLE_RWD_SIDEBAR:
      return {
        ...state,
        isOpen: !state.isOpen,
      };
    case Actions.CLOSE_RWD_SIDEBAR:
      return {
        ...state,
        isOpen: false,
      };
    case Actions.OPEN_RWD_SIDEBAR:
      return {
        ...state,
        isOpen: true,
      };
    default:
      return state;
  }
}

export function getRwdSidebarOpen(state) {
  return state.isOpen;
}

export function toggleRwdSidebar() {
  return {
    type: Actions.TOGGLE_RWD_SIDEBAR,
  };
}

export function closeRwdSidebar() {
  return {
    type: Actions.CLOSE_RWD_SIDEBAR,
  };
}

export function openRwdSidebar() {
  return {
    type: Actions.OPEN_RWD_SIDEBAR,
  };
}
