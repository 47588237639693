import "@fontsource/noto-sans-tc";
import "@fontsource/roboto";
import { navigate, withPrefix } from "gatsby";
import React, { Component, useEffect } from "react";
import { Helmet } from "react-helmet";
import MessengerCustomerChat from "react-messenger-customer-chat";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "./ActionCreator";
import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";
import Delighters from "./Components/ReactDelighters";
import LoginModal from "./Modals/LoginModal";
import Selectors from "./Selectors";
import * as Theme from "./Theme";
import Constants from "./constants";
import ScrollToTopButton from "./Components/ScrollToTopButton";
import "./index.css";

function normalizePath(path) {
  if (path.slice(0, 2) === "//") {
    path = path.slice(1);
  }

  if (path[path.length - 1] !== "/") {
    path += "/";
  }

  return path;
}

function Layout(props) {
  const { location, profile, autoLoginDone, seo = {} } = props;

  const pathname = normalizePath(location.pathname);

  const { loading } = React.useMemo(
    () => ({
      loading: !autoLoginDone && pathname.indexOf("auth") === -1,
    }),
    [pathname, profile, autoLoginDone]
  );

  useEffect(() => {
    if (
      Constants.maintenance &&
      typeof window !== "undefined" &&
      location.pathname !== "/maintenance/"
    ) {
      navigate("/maintenance/");
    }
  }, [location]);

  useEffect(() => {
    if (!Constants.debug) {
      const appendScript = scriptToAppend => {
        const script = document.createElement("script");
        script.src = scriptToAppend;
        document.body.appendChild(script);
      };

      setTimeout(() => {
        setTimeout(() => {
          appendScript("https://static.getbutton.io/widget/bundle.js?id=6iVKC");
        }, 3000);
      }, 600);
    }
  }, []);

  useEffect(() => {
    // ga4 initial
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function() {
        window.dataLayer.push(arguments);
      };
      window.gtag("js", new Date());
      window.gtag("config", Constants.googleAnalyticsId, {
        page_path: location.pathname,
        debug_mode: Constants.debug,
      });
    }
  }, [location]);

  return (
    <Wrapper>
      <Helmet>
        <link rel="shortcut icon" href="/sensationsprint.ico" />
        <title>{seo.title}</title>

        <meta
          name="facebook-domain-verification"
          content="ed0wdekirnpc6zh9mw76nriigv8nnt"
        />

        {/* <meta
          name="google-site-verification"
          content="AX0JaY-i-O8ZV1ilNJ193pyZurNwbiSQzfvuX6Q6Bgo"
        /> */}

        <meta
          name="google-site-verification"
          content="xiFixRDC6RjUwQYiZAie3h5SYTmBpFG4u-zF6-ALQ-k"
        />

        {seo.keywords && <meta name="keywords" content={seo.keywords} />}

        {seo.title && <meta property="og:title" content={seo.title} />}
        {seo.description && (
          <meta name="description" content={seo.description} />
        )}
        {seo.image && <meta name="image" content={seo.image} />}
        {seo.description && (
          <meta property="og:description" content={seo.description} />
        )}
        {seo.image && <meta property="og:image" content={seo.image} />}

        {Constants.env !== "prod" && (
          <meta name="robots" content="noindex, nofollow" />
        )}

        {Constants.env === "prod" && !Constants.debug && (
          <script
            src={withPrefix("yahoo-ad-tracking.js")}
            type="application/javascript"
          />
        )}

        <script src="https://api.payuni.com.tw/widget/sdk.bundle.js"></script>

        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${Constants.googleAnalyticsId}`}
        />
      </Helmet>
      <Delighters>
        <Navbar pathname={pathname} />
        {loading ? <Content></Content> : <Content>{props.children}</Content>}

        <Footer pathname={pathname} />
      </Delighters>
      <ScrollToTopButton />
      <LoginModal />
      {!Constants.debug && (
        <MessengerCustomerChat
          pageId={Constants.facebookMessagerPageId}
          appId={Constants.facebookMessagerAppId}
          htmlRef={
            typeof window !== "undefined" ? window.location.pathname : undefined
          }
          language="zh_TW"
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-height: calc(100vh);
  width: 100%;
  background-color: white;
  padding-top: ${Theme.navbarHeight}px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${Theme.breakpoints.lg}px) {
    // pad
    padding-top: ${Theme.navbarHeightMore}px;
  }
`;

const Content = styled.div`
  flex: 1;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;

  @keyframes rollout {
    0% {
      transform: translateY(50%);
    }
    100% {
      transform: none;
    }
  }

  @keyframes zoomout {
    0% {
      transform: scale(1.05);
      transform-origin: center;
    }
    100% {
      transform: scale(1);
      transform-origin: center;
    }
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
    autoLoginDone: Selectors.autoLoginDone(state),
  }),
  ActionCreator
)(Layout);
